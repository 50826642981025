import React, { useState } from "react";
import {
  Title,
  Space,
  Box,
  Text,
} from "@mantine/core";
import {
  useForm,
} from "react-hook-form";
import {
  SelectorButtonRef,
  YES_OR_NO_CHOICE,
  DentalArchesSvg,
} from "@jasper/shared";

const PartialDentureInformations = ({
  watch,
  errors,
  setValue,
  control,
  defaultTeeth,
}) => {
  const watchIsReplacement = watch("isReplacement");

  const [selectedTeeth, setSelectedTeeth] = useState<number[]>(defaultTeeth ?? []);

  return (
    <>
      <Title order={2}>Information sur votre prothèse amovible partielle</Title>
      <Space h="md" />
      <SelectorButtonRef
        label="S'agit-il d'un remplacement de prothèse ?"
        name="isReplacement"
        data={YES_OR_NO_CHOICE}
        onSubmit={(selectedValue) => setValue("isReplacement", selectedValue)}
        control={control}
        errors={errors}
        required={true}
        watch={watchIsReplacement}
      />
      <Text
        fw={490}
        size="sm"
      >
        Sélectionnez les dents à inclure dans la PAP
      </Text>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <DentalArchesSvg
          teeth={selectedTeeth}
          width={275}
          height={366}
          onClick={(tooth) => {
            const index = selectedTeeth.indexOf(tooth);
            if (index >= 0){
              setSelectedTeeth(selectedTeeth.filter((t) => t !== tooth));
              setValue("teeth", selectedTeeth.filter((t) => t !== tooth));
              return;
            }
            setSelectedTeeth([...selectedTeeth, tooth])
            setValue("teeth", [...selectedTeeth, tooth]);
          }}
        />
      </Box>
    </>
  );
};

export default PartialDentureInformations;
