import {
  Breadcrumbs,
  Anchor,
  Space,
} from "@mantine/core";

import {
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  isUserStaffNotAdmin,
  isUserProvider,
  OrderStatus,
} from "@jasper/shared";

const LabsBreadcrumbs = (props) => {

  const location = useLocation();
  const navigate = useNavigate();

  const isOrderEditPage = (): boolean => {
    const url = window.location.href;
    return (url.search("orders") >= 0 && url.search("edit") >= 0);
  }

  const goToPreviousPage = () => {
    return (location?.state?.from === "HELP_LIST" || location?.state?.from === "CHAT_LIST");
  }

  const getUrlForDefaultPage = () => {
    if (isUserStaffNotAdmin(props.user)){
      return "/groups";
    }
    if (isUserProvider(props.user)){
      return `/home?status=${OrderStatus.WAITING_FOR_PRODUCTION}&page=0&search=${props.searchText}`;
    }
    return `/home?status=ALL&page=0&search=${props.searchText}`;
  };

  const getNameForDefaultPage = () => {
    if (location?.state?.from === "HELP_LIST" || location?.state?.from === "CHAT_LIST"){
      return "Notifications";
    }
    return "Orders";
  }

  const items = [
    { title: getNameForDefaultPage(), href: getUrlForDefaultPage() },
    { title: props.orderReference, href: `/orders/${props.orderId}` },
    ...isOrderEditPage()
      ? [{ title: "Edit", href: `/orders/${props.orderId}/edit/` }]
      : []
  ];

  const test = async() => {
  console.log(await navigate(-1));
  };

  return (
    <>
      <Breadcrumbs>
        {items.map((item: { title: string, href: string }, index: number) => (
          <div href={item.href} key={index}>
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => props.navigate(goToPreviousPage() ? -1 : item.href)}
            >
              {item.title}
            </span>
          </div>
        ))}
      </Breadcrumbs>
    </>
  );
};

export default LabsBreadcrumbs;
