import {
  useNavigate,
} from "react-router-dom";
import { IconArrowNarrowLeft } from '@tabler/icons-react';

const PrecedentComponent = () => {
  const navigate = useNavigate();
  return (
    <p
      style={{ margin: "auto", marginRight: "1rem", lineHeight: "normal", cursor: "pointer" }}
      onClick={() => navigate(-1)}
    >
      <IconArrowNarrowLeft/>
    </p>
  )
}

export default PrecedentComponent;
