import React from "react";
import {
  Title,
} from "@mantine/core";
import {
  useForm,
} from "react-hook-form";
import {
  SelectorButtonRef,
  ShadeSelection,
} from "@jasper/shared";

const PartialDentureProthesis = ({
  materialChoices,
  gingivaShadeChoices,
  order,
  accountProductPreferences,
  anatomyItemChoicesData,
  watch,
  errors,
  setValue,
  control,
}) => {
  const watchMaterial = watch("material");
  const watchGingivaShade = watch("gingivaShade");
  const watchShadeType = watch("shadeType");

  return (
    <>
      <Title order={2}>Matériau et teinte - prothèse amovible partielle</Title>
      <SelectorButtonRef
        label="Matériau"
        name="material"
        data={materialChoices}
        onSubmit={(selectedValue) => setValue("material", selectedValue)}
        control={control}
        errors={errors}
        required={true}
        watch={watchMaterial}
      />
      <ShadeSelection
        watchShadeType={watchShadeType}
        control={control}
        errors={errors}
        anatomyItemChoicesData={anatomyItemChoicesData}
        onSelect={(selectedValue) => setValue("shadeType", selectedValue)}
        order={order}
        accountProductPreferences={accountProductPreferences}
      />
      <SelectorButtonRef
        label="Teinte de gencive"
        name="gingivaShade"
        data={gingivaShadeChoices}
        onSubmit={(selectedValue) => setValue("gingivaShade", selectedValue)}
        control={control}
        errors={errors}
        required={true}
        watch={watchGingivaShade}
      />
    </>
  );
};

export default PartialDentureProthesis;
