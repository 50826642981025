import { gql } from "@apollo/client";

export const GENERATE_TRACEABILITY_DOCUMENT = gql`
  mutation GenerateTraceabilityDocument(
    $orderBillingWhere: OrderBillingWhereUniqueInput!,
    $updateOrderBillingWhere: OrderBillingWhereUniqueInput!,
    $providerReference: String!,
  ) {
    assignToProviderAndGenerateBilling(
      updateOrderBillingWhere: $updateOrderBillingWhere,
      providerReference: $providerReference
    ) {
      id
    }
    generateOrderBillingTraceabilityDocuments(
      orderBillingWhere: $orderBillingWhere
    ) {
      id
    }
  }
`
