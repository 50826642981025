import React, { useRef, useEffect, useState } from 'react';
import './chat.css';
import { messages } from '../utils/chat.utils';
import { Message } from './Message';
import { MessageInput } from './MessageInput';
import { Badge, Modal } from '@mantine/core';
import { Image } from '../utils/carousel.utils';
import { Carousel } from '../Carousel/Carousel';
import InfiniteScroll from 'react-infinite-scroll-component';

export interface ChatProps {
  messages: messages[];
  currentUserGroup: string;
  setMessageToSend: (newValue: string) => void;
  send: () => void;
  filesToSend: File[];
  setFilesToSend: (files: File[]) => void;
  loading: () => void;
  hasMore: boolean;
  fetchMoreMessages: () => void;
  scrollBottom: boolean
}

export const Chat = ({
  messages,
  currentUserGroup,
  setMessageToSend,
  send,
  filesToSend,
  setFilesToSend,
  hasMore,
  fetchMoreMessages,
  loading,
  scrollBottom
}: ChatProps) => {
  const container = useRef<HTMLDivElement>(null);
  const componentCore = useRef(null);
  const modal = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [renderingFiles, setRenderingFiles] = useState<Image[]>([]);
  const [isRenderingSavedFiles, setIsRenderingSavedFiles] = useState(false);
  const [imageToDelete, setImageToDelete] = useState<Image | null>(null);
  const [temp, setTemp] = useState(false);
  const [previousHeight, setPreviousHeight] = useState(0);

  const deletingTemporaryFiles = (file: Image) => {
    setFilesToSend((prev) => prev.filter((f) => f.name !== file.key));
    setRenderingFiles((prev) => prev.filter((f) => f.id !== file.id));
    setImageToDelete(null);
  }; 

  useEffect(() => {
    if (imageToDelete) {
      deletingTemporaryFiles(imageToDelete);
    }
  }, [imageToDelete]);

  useEffect(() => {
    if (renderingFiles.length === 0 && showModal) {
      setShowModal(false);
    }
  }, [renderingFiles.length, showModal]);

  useEffect(() => {
    if (container.current && scrollBottom === false) {
      container.current.scrollTop = container.current.scrollHeight - previousHeight;
    }
    if (container.current && scrollBottom === true) {
      container.current.scrollTop = container.current.scrollHeight;
    }
  }, [messages]);

  useEffect( () => {
    if (
      ((componentCore.current.getBoundingClientRect().height - 60) < container.current.getBoundingClientRect().height 
      || container.current.scrollTop === 0) && hasMore
    ) {
      fetchMoreMessages();
    }
  },[hasMore]);

  return (
    <div className="box-component" ref={componentCore}>
      <Modal
        ref={modal}
        opened={showModal}
        onClose={() => setShowModal(false)}
        closeOnClickOutside
      >
        {renderingFiles && (
          <Carousel
            imageList={renderingFiles}
            hasDeleteOption={!isRenderingSavedFiles}
            setImageToDelete={setImageToDelete}
            isSavedFile={isRenderingSavedFiles}
          />
        )}
      </Modal>

      <div
        className="messages-container"
        id="chat-container"
        ref={container}
      >
        <InfiniteScroll
          dataLength={messages.length}
          next={fetchMoreMessages}
          hasMore={hasMore}
          isLoading={loading}
          loader={<p>Loading...</p>}
          inverse={true}
          scrollableTarget="chat-container"
          onScroll={()=> {
            if (
              ((componentCore.current.getBoundingClientRect().height - 60) < container.current.getBoundingClientRect().height 
              || container.current.scrollTop === 0) && hasMore
            ) {
              setPreviousHeight(container.current.scrollHeight)
              fetchMoreMessages();

            }}
          }
        >
          {messages.map((message, index) => (
            <Message
              key={`message-group-${message.id}`}
              setShowModal={(value) => {
                setShowModal(value);
                setIsRenderingSavedFiles(true);
              }}
              setRenderingFiles={setRenderingFiles}
              message={message}
              position={message?.userGroup?.id === currentUserGroup ? 'right' : 'left'}
              previousDate={
                index > 0 ? messages[index - 1].createdAt : null
              }
              previousMessageUser={
                index > 0 ? messages[index - 1].userGroup.id : null
              }
            />
          ))}
        </InfiniteScroll>

        {messages.length === 0 && <Badge className="date">Today</Badge>}
      </div>

      <MessageInput
        setShowModal={(value) => {
          setShowModal(value);
          setIsRenderingSavedFiles(false);
        }}
        send={send}
        setMessageToSend={setMessageToSend}
        setFiles={setFilesToSend}
        setRenderingFiles={setRenderingFiles}
        renderingFiles={renderingFiles}
        files={filesToSend}
        temp={temp}
        setTemp={setTemp}
        uploadFile={() => {}}
      />
    </div>
  );
};
