import React, { useState } from "react";
import {
  Stepper,
  Button,
  Group,
} from "@mantine/core";
import {
  useForm,
} from "react-hook-form";
import {
  useLocation,
} from "react-router-dom";

import {
  STYLE,
} from "../../../../types/constants";
import {
  ItemType,
} from "../../../../types/enums";
import {
  deduceShadeType,
} from '../../../../utils/item.utils';
import PartialDentureInformations from "./PartialDentureinformations";
import PartialDentureProthesis from "./PartialDentureProthesis";
import PartialDentureHooks from "./PartialDentureHooks";
import PartialDentureFlux from "./PartialDentureFlux";

const PartialDentureWorkflow = ({
  materialChoices,
  gingivaShadeChoices,
  order,
  accountProductPreferences,
  anatomyItemChoicesData,
  onSubmit,
}) => {
  const location = useLocation();
  const savedItem = (location?.state?.product?.removableItem ?? []).find((item) => item.itemType === ItemType.PARTIAL_DENTURE);
  console.log("***", savedItem);

  const [active, setActive] = useState(0);

  const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));
  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

  const {
    control,
    watch,
    handleSubmit,
    formState: {errors},
    setValue,
  } = useForm({
    defaultValues: {
      isReplacement: savedItem?.isReplacement,
      workflowType: savedItem?.workflowType,
      hookComment: savedItem?.dentistNotes,
      teeth: savedItem?.teeth,
      material: savedItem?.itemMaterial?.id,
      shadeType: deduceShadeType(savedItem?.itemShade?.id, order?.user?.id, accountProductPreferences),
      shade: savedItem?.teethShade?.id,
      gingivaShade: savedItem?.gingivaShade?.id,
    },
  });

  return (
    <>
      <Stepper active={active} onStepClick={setActive}>
        <Stepper.Step label="Informations">
          <PartialDentureInformations
            watch={watch}
            errors={errors}
            setValue={setValue}
            control={control}
            defaultTeeth={savedItem?.teeth}
          />
        </Stepper.Step>
        <Stepper.Step label="Prothèse">
          <PartialDentureProthesis
            materialChoices={materialChoices}
            gingivaShadeChoices={gingivaShadeChoices}
            order={order}
            accountProductPreferences={accountProductPreferences}
            anatomyItemChoicesData={anatomyItemChoicesData}
            watch={watch}
            errors={errors}
            setValue={setValue}
            control={control}
          />
        </Stepper.Step>
        <Stepper.Step label="Crochets">
          <PartialDentureHooks
            watch={watch}
            errors={errors}
            setValue={setValue}
            control={control}
            defaultComment={savedItem?.dentistNotes}
          />
        </Stepper.Step>
        <Stepper.Step label="Flux de travail">
          <PartialDentureFlux
            watch={watch}
            errors={errors}
            setValue={setValue}
            control={control}
          />
        </Stepper.Step>
      </Stepper>

      <Group justify="center" mt="xl">
        <Button variant="default" onClick={prevStep}>Back</Button>
        <Button onClick={active !== 3 ? nextStep : handleSubmit(onSubmit)} style={{ backgroundColor: STYLE.primary }} >Next step</Button>
      </Group>
    </>
  )
};

export default PartialDentureWorkflow;
