import React, {
  useState,
  useEffect,
} from "react";

import SelectorButtonRef from "./SelectorButtonRef";
import SelectInputRef from "./SelectInputRef";
import {
  FixedProsthesisShadeType,
  RemovableProsthesisShadeType,
} from "../types/enums";
import {
  filterShadeByShadeType,
} from "../utils/item.utils";
import {
  findUserPreference,
} from "../utils/product.utils";

const ShadeSelection = ({
  watchShadeType,
  control,
  errors,
  anatomyItemChoicesData,
  onSelect,
  accountProductPreferences,
  order,
  isRemovableItem = false,
}) => {

  const [shadeFormChoices, setShadeFormChoice] = useState(filterShadeByShadeType(anatomyItemChoicesData.getItemShadeWhere ?? [], watchShadeType));

  useEffect(() => {
    setShadeFormChoice(filterShadeByShadeType(anatomyItemChoicesData?.getItemShadesWhere, watchShadeType));
  }, [watchShadeType]);

  useEffect(() => {
    const shadeType = findUserPreference(order?.user?.id, accountProductPreferences)?.shadeType;
    if (shadeType && shadeType !== watchShadeType){
      onSelect(shadeType);
    }
    setShadeFormChoice(filterShadeByShadeType(anatomyItemChoicesData?.getItemShadesWhere, watchShadeType));
  }, [accountProductPreferences]);

  return (
    <>
      <SelectorButtonRef
        label="Type de teintier"
        name="shadeType"
        data={Object.keys(isRemovableItem ? RemovableProsthesisShadeType : FixedProsthesisShadeType).map((shadeType) => ({ value: shadeType, label: shadeType.replaceAll("_", " ") }))}
        onSubmit={(selectedValue) => onSelect(selectedValue)}
        control={control}
        errors={errors}
        watch={watchShadeType}
        required={true}
      />
      <SelectInputRef
        label="Teinte"
        name="shade"
        data={shadeFormChoices}
        control={control}
        errors={errors}
        required={true}
      />
    </>
  );
};

export default ShadeSelection;
