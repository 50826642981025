export const getApiUrl = () => {
  switch (import.meta.env.MODE) {
    case "test":
      return "https://test.api.jasperdental.fr";

    case "staging":
      return "https://staging.api.jasperdental.fr";

    case "production":
      return "https://api.jasperdental.fr";

    default:
      return "http://localhost:5050";
  }
};

export const getWebsocketApiUrl = () => {
  switch (import.meta.env.MODE) {
    case "staging":
      return "https://websocket.staging.jasperdental.fr";

    case "production":
      return "https://websocket.jasperdental.fr";

    default:
      return "http://localhost:5051";
  }
};
